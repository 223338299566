ion-card {
	--background: #000;
	margin-bottom: 30px;
}

.ion-img {

	object-fit: cover;
	cursor:  pointer;
	
}

.ion-img:hover {
	filter: opacity(.8);

}